<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      app
      temporary
    >
      <!-- <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>John Leider</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider> -->

      <v-list>
        <div
            v-for="(item, index) in menu"
            :key="index">
            <v-list-item
              v-if="item.sub.length < 1"
              :to="item.link"
              color="transparent"
              :style="clipped ? 'padding: 0px 13px;' : ''">
              <v-list-item-avatar
                size="40"
                tile
                class="mr-4"
                :class="$route.path === '/' ? item.link.split('/')[1] === 'beranda' ? '' : 'box_false' : item.link.split('/')[1] === $route.fullPath.split('/')[1] ? '' : 'box_false'"
                :color="$route.path === '/' ? item.link.split('/')[1] === 'beranda' ? '#C8EEFF' : '#FFFFFF' : item.link.split('/')[1] === $route.fullPath.split('/')[1] ? '#C8EEFF' : '#FFFFFF'"
                :style="$route.path === '/' ? item.link.split('/')[1] === 'beranda' ? `border: 2px solid ${set_color};` : '' : item.link.split('/')[1] === $route.fullPath.split('/')[1] ? `border: 2px solid ${set_color};` : ''"
                style="border-radius: 10px !important;">
                <v-icon :color="$route.path === '/' ? item.link.split('/')[1] === 'beranda' ? `${set_color}` : '' : item.link.split('/')[1] === $route.fullPath.split('/')[1] ? `${set_color}` : ''">{{ item.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="body-2"
                  @mouseover="voiceHover(item.title)"
                  :class="$route.path === '/' ? item.link.split('/')[1] === 'beranda' ? '' : 'grey--text' : item.link.split('/')[1] === $route.fullPath.split('/')[1] ? '' : 'grey--text'"
                  :style="$route.path === '/' ? item.link.split('/')[1] === 'beranda' ? `color: ${set_color}; font-weight: 600;` : '' : item.link.split('/')[1] === $route.fullPath.split('/')[1] ? `color: ${set_color}; font-weight: 600;` : ''">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-if="item.title !== 'Setting' && item.sub.length > 0"
              v-model="item.active"
              :color="set_color"
              :append-icon="item.sub.length > 0 ? 'mdi-pan-down' : ''"
              no-action
              :class="clipped ? 'clipped_header' : ''">
              <template v-slot:activator>
                <v-list-item-avatar
                  size="40"
                  tile
                  class="mr-4"
                  :class="item.active || $route.name === item.link ? '' : 'box_false'"
                  :color="item.active || $route.name === item.link ? '#C8EEFF' : '#FFFFFF'"
                  :style="item.active || $route.name === item.link ? `border: 2px solid ${set_color};` : ''"
                  style="border-radius: 10px !important;">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="body-2"
                    @mouseover="voiceHover(item.title)"
                    :class="item.active || $route.name === item.link? 'font-weight-bold' : 'grey--text'"
                    :style="$route.name === item.link ?  `color: ${set_color} !important;`: item.active === $route.fullPath ? `color: ${set_color};` : ''">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <div v-if="item.title === 'Tentang Kami'" class="ml-4">
                <v-list-item
                  v-for="(item_sub, index_sub) in item.sub"
                  :key="index_sub"
                  :to="`${item_sub.link}`"
                  color="transparent">
                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2"
                      @mouseover="voiceHover(item_sub.title)"
                      :class="item_sub.link === $route.fullPath ? 'font-weight-bold' : 'grey--text'"
                      :style="item_sub.link === $route.fullPath ? `color: ${set_color};` : ''">
                      {{ item_sub.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>

              <div v-if="item.title === 'Dokumen Hukum' && item.sub.length > 0" class="ml-4">
                <div
                  v-for="(item_sub, index_sub) in item.sub"
                  :key="index_sub">
                  <v-list-group
                    v-model="item_sub.active"
                    :color="set_color"
                    sub-group
                    :append-icon="item_sub.sub !== undefined && item_sub.sub.length > 0 ? 'mdi-pan-down' : ''"
                    no-action>
                    <template v-slot:activator>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2"
                          @mouseover="voiceHover(item_sub.title)"
                          :class="item_sub.active || $route.name === item_sub.link? 'font-weight-bold' : 'grey--text'"
                          :style="$route.name === item_sub.link ?  `color: ${set_color} !important;`: item_sub.active === $route.fullPath ? `color: ${set_color};` : ''">
                          {{ item_sub.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <div class="ml-4">
                      <v-list-item
                        v-for="(item_subMenu, index_subMenu) in item_sub.sub"
                        :key="index_subMenu"
                        @click="toList(item_subMenu.link)"
                        color="transparent">
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2"
                            @mouseover="voiceHover(item_subMenu.title)"
                            :class="item_subMenu.link === $route.fullPath ? 'font-weight-bold' : 'grey--text'"
                            :style="item_subMenu.link === $route.fullPath ? `color: ${set_color};` : ''">
                            {{ item_subMenu.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>

                  </v-list-group>
                </div>
              </div>

              <div v-if="item.title === 'Informasi'" class="ml-4">
                <v-list-item
                  v-for="(item_sub, index_sub) in item.sub"
                  :key="index_sub"
                  :to="`${item_sub.link}`"
                  color="transparent">
                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2"
                      @mouseover="voiceHover(item_sub.title)"
                      :class="item_sub.link === $route.fullPath ? 'font-weight-bold' : 'grey--text'"
                      :style="item_sub.link === $route.fullPath ? `color: ${set_color};` : ''">
                      {{ item_sub.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>

            </v-list-group>
          </div>
    </v-list>
    </v-navigation-drawer>


    <v-app-bar
      app
      fixed
      class="px-2"
      :class="$route.name === '/' || $route.name === 'beranda' ? 'header' : ''"
      :prominent="false"
      :hide-on-scroll="false"
      scroll-target="#scrolling-techniques-4"
      :style="`background-color: #fff`"
      style="height: 64px; z-index: 3; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
      <v-container class="px-0 pt-1">
        <v-row v-if="selected.search === false">
          <v-col
            class="d-flex align-center pa-0">
            <div>
              <v-btn
                :color="`${set_color}`"
                elevation="0"
                icon
                min-width="40"
                @click="drawer = !drawer"
                class="text-capitalize px-0"
                style="border-radius: 6px;"
                >
                <v-icon size="20">mdi-menu</v-icon>
              </v-btn>
            </div>

            <v-spacer />

            <div>
              <nuxt-link
                to="/beranda"
                class="d-flex align-center">
                <v-img
                  contain
                  width="30"
                  height="auto"
                  alt="JDIH Setwan Kabupaten Klaten"
                  :src="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? require('@/assets/logo/jdihn.png') : require('@/assets/logo/jdihn.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </nuxt-link>
            </div>

            <div>
              <nuxt-link
                to="/beranda"
                class="d-flex align-center">
                <v-img
                  contain
                  width="80"
                  height="auto"
                  alt="JDIH Setwan Kabupaten Klaten"
                  :src="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? require('@/assets/logo/favicon.png') : require('@/assets/logo/favicon.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </nuxt-link>
            </div>

            <v-spacer />

            <div>
              <!-- <v-btn
                  :color="`${set_color}`"
                  elevation="0"
                  icon
                  min-width="40"
                  @click="selected.search = true"
                  class="text-capitalize px-0"
                  style="border-radius: 6px;"
                >
                <v-icon size="20">mdi-magnify</v-icon>
                </v-btn> -->
            </div>
          </v-col>
        </v-row>

        <v-row v-if="selected.search === true">
          <v-col
            class="d-flex align-center pa-0">
            <div>
              <v-btn
                :color="`${set_color}`"
                elevation="0"
                icon
                min-width="40"
                @click="selected.search = false"
                class="text-capitalize px-0"
                style="border-radius: 6px;"
                >
                <v-icon size="20">mdi-close</v-icon>
              </v-btn>
            </div>

            <v-spacer />

            <div class="d-flex" style="width: 100%">
              <v-text-field
                solo
                hide-details
                clearable
                dense
                v-model="search"
                @click:clear="search = ''; changeQuery();"
                style="border-radius: 6px 0px 0px 6px !important;"
                :color="`${set_color}`"
                :placeholder="`Cari peraturan yang Anda butuhkan . . .`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
              <v-btn
                  :color="`${set_color}`"
                  elevation="0"
                  dark
                  min-width="40"
                  height="38"
                  @click="changeQuery()"
                  class="text-capitalize"
                  style="border-radius: 0px 6px 6px 0px;"
                >
                <v-icon size="20">mdi-magnify</v-icon>
                </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        clipped: false,
        drawer: false,
        subMenu: false,
        subMenu2: false,
        subMenu3: false,
        dialog: {
          auth: false,
          otp: false,
          info: false
        },
        selected: {
          auth: 'login',
          category: '',
          search: false
        },
        search: '',
        category: [
          {
            name: 'Peraturan Daerah',
            data: "76"
          },
          {
            name: 'Keputusan Bupati',
            data: "30"
          },
          {
            name: 'Rancangan Peraturan Daerah',
            data: "21"
          },
          {
            name: 'Produk Hukum Desa',
            data: "21"
          },
          {
            name: 'Abstrak Peraturan Daerah',
            data: "12"
          },
          {
            name: 'MOU dan PKS',
            data: "5"
          },
          {
            name: 'Surat Edaran Bupati',
            data: "9"
          },
          {
            name: 'Propemperda',
            data: "2"
          },

          {
            name: 'Peraturan Bupati',
            data: "15"
          },
          {
            name: 'Instruksi Bupati',
            data: "20"
          },
          {
            name: 'Rancangan Peraturan Bupati',
            data: "33"
          },
          {
            name: 'Naskah Akademis',
            data: "21"
          },
          {
            name: 'Staatsblad',
            data: "5"
          },
          {
            name: 'Surat Edaran Sekda',
            data: "2"
          },
          {
            name: 'Peraturan Terjemahan Resmi',
            data: "76"
          },
        ],
        produkHukum: [
          {
            name: 'Peraturan Daerah',
            data: "76"
          },
          {
            name: 'Keputusan Bupati',
            data: "30"
          },
          {
            name: 'Rancangan Peraturan Daerah',
            data: "21"
          },
          {
            name: 'Abstrak Peraturan Daerah',
            data: "12"
          },

          {
            name: 'Peraturan Bupati',
            data: "15"
          },
          {
            name: 'Instruksi Bupati',
            data: "20"
          },
          {
            name: 'Rancangan Peraturan Bupati',
            data: "33"
          },
          {
            name: 'Produk Hukum Desa',
            data: "12"
          },
          {
            name: 'Staatsblad',
            data: "5"
          },
          {
            name: 'Peraturan Terjemahan Resmi',
            data: "76"
          },
        ],
        menu: [
          {
            title: 'Beranda',
            link: '/beranda',
            icon: 'mdi-home',
            sub: []
          },
          {
            title: 'Tentang Kami',
            link: '/visi-misi',
            icon: 'mdi-account',
            sub: [
              {
                title: 'Dasar Hukum',
                link: '/dasar-hukum'
              },
              {
                title: 'Visi dan Misi',
                link: '/visi-misi'
              },
              {
                title: 'Tugas Pokok, Fungsi dan Program Kerja',
                link: '/tugas-pokok-fungsi-dan-program-kerja'
              },
              {
                title: 'Hubungi Kami',
                link: '/hubungi-kami'
              },
              {
                title: 'Anggota JDIH',
                link: '/anggota-jdih'
              },
              {
                title: 'SOP',
                link: '/SOP'
              },
              {
                title: 'Tim Pengelola',
                link: '/struktur-organisasi'
              }
            ]
          },
          // {
          //   title: 'PPID',
          //   link: '/PPID'
          // },
          // {
          //   title: 'Produk Hukum Desa',
          //   link: '/page?category=produk-hukum-desa'
          // },
          {
            title: 'Dokumen Hukum',
            link: '/page',
            icon: 'mdi-file-document',
            sub: []
          },
          // {
          //   title: 'Dokumen Lainnya',
          //   link: '',
          //   sub: [
          //     {
          //       title: 'Naskah Akademis',
          //       link: '/page?category=naskah-akademis'
          //     },
          //     {
          //       title: 'MOU dan PKS',
          //       link: '/page?category=mou-dan-pks'
          //     },
          //     {
          //       title: 'Surat Edaran Bupati',
          //       link: '/page?category=surat-edaran-bupati'
          //     },
          //     {
          //       title: 'Surat Edaran Sekda',
          //       link: '/page?category=surat-edaran-sekda'
          //     },
          //     {
          //       title: 'Propemperda',
          //       link: '/page?category=propemperda'
          //     }
          //   ]
          // },
          {
            title: 'Informasi',
            link: '/berita',
            icon: 'mdi-information',
            sub: [
              {
                title: 'Berita',
                link: '/berita'
              },
              {
                title: 'Pengumuman',
                link: '/pengumuman'
              },
              {
                title: 'Video',
                link: '/video'
              },
              {
                title: 'Galeri',
                link: '/galeri'
              },
              {
                title: 'Statistik',
                link: '/statistik'
              }
            ]
          },
          // {
          //   title: 'Download',
          //   link: '/pariwisata',
          //   sub: [
          //     {
          //       title: 'Dokumen',
          //       link: '/dokumen'
          //     },
          //     {
          //       title: 'Katalog Perda',
          //       link: '/katalog-perda'
          //     },
          //     {
          //       title: 'Katalog Perbup',
          //       link: '/katalog-perbup'
          //     }
          //   ]
          // },
          // {
          //   title: 'Koleksi Buku',
          //   link: '/buku'
          // },
        ]
      }
    },
    watch: {
      '$route': function() {
        if (this.$route.path.split('/')[1] !== 'page') {
          this.search = ''
          this.selected.category = ''
        }
      },
      '$route.query.jenis': function(val) {
        for(let i = 0; i < this.category.length; i++){
          if (this.category[i].slug === val) {
            this.selected.category = this.category[i].slug
          } else {
            // status = false
          }
        }
      },
    },
    components: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      token () {
        return this.$store.state.member.auth.token
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },

      company () {
        return this.$store.state.header.config
      },
      // role_admin () {
      //   if (this.$route.name === null) {
      //     this.$router.push('/404')
      //   } else {
      //     return this.$route.name.includes('admin')
      //   }
      // }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) {
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        }
      }

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()

      // this.category = this.category.map(item => {
      //   const container = {};

      //   container.name = item.name;
      //   container.slug = (item.name.split(' ').join('-')).toLowerCase();
      //   container.data = item.data

      //   // this.category = container;
      //   return container
      // })

      this.fetchCategory()

      if(this.$route.query.search !== undefined){
        this.search = this.$route.query.search
      } else {
        this.search = ''
      }

      if(this.$route.path.split('/')[1] !== 'page'){
        this.selected.category = this.category[0].slug
      }

      // console.log(this.$route.path.split('/')[1])
    },
    beforeCreate() {

    },
    created() {

    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
  responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetchCategory () {
        // this.process.load = true

        let params = {
          limit: 1000000
        }

        await this.$axios.$get(`${process.env.API}sites/doc/doc_type`, { params })
        .then(response => {
          let res = response

          if (res.status === 200) {
            this.category = [
              {
                name: "Semua Kategori",
                slug: "semua-kategori",
              }
            ]
            let category = []
            category = res.results.data.map(item => {
              const container = {};

              container.title = item.title;
              container.id = item.id;
              container.par_id = item.par_id;
              container.singkatan = item.singkatan
              container.link = `/page?jenis=${(item.title.split(' ').join('-')).toLowerCase()}`;
              return container
            })
            this.menu[2].sub = this.changeData(category)

            let selecCategory = []
            selecCategory = res.results.data.map(item => {
              const container = {};
              container.par_id = item.par_id;
              container.name = item.title;
              container.slug = (item.title.split(' ').join('-')).toLowerCase();
              return container
            })

            for (let i in selecCategory) {
              if(selecCategory[i].par_id !== '0') {
                this.category.push(
                  {
                    name: selecCategory[i].name,
                    slug: selecCategory[i].slug
                  }
                )
              }

            }




            for(let i = 0; i < this.category.length; i++){
              if (this.category[i].slug === this.$route.query.jenis) {
                this.selected.category = this.category[i].slug
              } else {
                // status = false
              }
            }
          }
        })
      },

      changeData (data_array) {
        function filter (array, condition) {
          let result = []

          for (let i = 0; i < array.length; i++) {
            if (condition(array[i])) {
              result.push(array[i])
            }
          }

          return result
        }

        function getChilds (parentKey, items) {
          let subItems = filter(items, function (n) {
            return n.par_id === parentKey
          })

          let result = []

          for (let i = 0; i < subItems.length; i++) {
            let subItem = subItems[i]

            let kids = getChilds(subItem.id, items)
            if (kids.length) {
              subItem.sub = kids
            }

            result.push(subItem)
          }

          if (parentKey.length) {
            return result
          } else {
            return result[0]
          }
        }

        const data = data_array

        let richMediaData = getChilds('0', data)

        return richMediaData
      },
      changeQuery() {
        if(this.$route.query.search === undefined) {
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category, 'search': this.search}})
          } else {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category, 'search': this.search, 'category': this.$route.query.category}})
          }
        } else if (this.$route.query.search !== undefined && this.search !== '') {
          // console.log('cobaaa')
          // router.push({ path: 'page', query: { search: null } })
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category, 'search': this.search}})
          } else {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category, 'search': this.search, 'category': this.$route.query.category}})
          }
          // this.$router.replace({'query': {'search': this.search}})
          // router.push({ path: 'page', query: { search: this.search } })
        } else if (this.$route.query.search !== undefined && this.search === '') {
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category}})
          } else {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category, 'category': this.$route.query.category}})
          }
        }
      },
      toFilterCategory() {
        if(this.$route.query.search === undefined) {
          // this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category}})
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category}})
          } else {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category, 'category': this.$route.query.category}})
          }
        } else {
          // this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category, 'search': this.search}})
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category, 'search': this.search}})
          } else {
            this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-kategori' : this.selected.category, 'search': this.search, 'category': this.$route.query.category}})
          }
        }
      },
      toList(link) {
        this.$router.push(`${link}`)
      },
      goTo (item) {
        if (item.type === 'logout') {
          Cookie.remove('token')
          Cookie.remove('user')
          Cookie.remove('fcm')
          Cookie.remove('notif')

          window.location = '/admin/auth/login'
        } else {
          this.$router.push('/admin/profil')
        }
      },
      goBack () {
        if (this.$route.name === 'reservasi-slug') {
          this.$router.push('/reservasi')
        } else if (this.$route.name === 'berita-slug') {
          this.$router.push('/berita')
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
</script>

<style>
  .header {
    /* background-color: transparent !important; */
  }
  .header-default {
    background-color: #a20000 !important;
  }
  .header-detail {
    background-color: transparent !important;
  }
  html:not([data-scroll="0"]) .header {
    /* background: url(@/assets/background/bg_basic.png); */
    /* background-color: #00000040 !important; */
    /* background-color: #a20000 !important; */
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: cover;
    /* opacity: 80% !important; */
  }
  .header .menu {
    /* color: #FFFFFF !important; */
    background-color: transparent !important;
    /* opacity: 60%; */
  }

  .sub .v-list-item {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #ffffff !important;
  box-shadow: none !important;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  margin-bottom: 0px !important;
}

.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #ffffff !important;
  /* border: 0.25px solid #4ab762; */
  border-width: 0.25px !important;
  border-radius: 6px;
  margin-bottom: 0px !important;
}

.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #ffffff !important;
  /* border: 0.25px solid #4ab762; */
  border-width: 0.25px !important;
  border-radius: 6px;
  margin-bottom: 0px !important;
}

.error_input_border.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #ffffff !important;
  border: 0.25px solid red !important;
  border-radius: 6px;
  margin-bottom: 0px !important;
}

.input-border-right-none.v-input--is-focused > .v-input__control > .v-input__slot {
  border-radius: 6px 0px 0px 6px !important;
}

.border-input-none.v-input--is-focused > .v-input__control > .v-input__slot {
  border-width: none !important;
}

.border-input-none > .v-input__control > .v-input__slot {
  border: none !important;
}

.border-input-none > .v-input__control > .v-input__slot > .v-input__prepend-inner{
  opacity: 0.6;
}

.border-input-none > .v-input__control > .v-input__slot > .v-text-field__slot{
  opacity: 0.6;
}

.input-border-right-none > .v-input__control > .v-input__slot {
  border-radius: 6px 0px 0px 6px !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: #FFFFFF !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  margin-bottom: 0px !important;
}
</style>
